/*=========================================================================================
  File Name: moduleVideoMutations.js
  Description: Video Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Notifications.unshift(item)
  },
  SET_Notifications(state, Notifications) {
    state.Notifications = Notifications
  },
  UPDATE_Notifications(state, Notifications) {
    const NotificationsIndex = state.Notifications.findIndex((p) => p.ID == Notifications.ID)
    Object.assign(state.Notifications[NotificationsIndex], Notifications)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Notifications.findIndex((p) => p.ID == itemId)
    state.Notifications.splice(ItemIndex, 1)
},
}
