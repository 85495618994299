<template>
  <div class="notification-card">
    <h2>{{ titleAr }}</h2>
    <p>{{ descriptionAr }}</p>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    titleAr: {
      type: String,
      required: true
    },
    descriptionAr: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.notification-card {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  max-width : 75%;
}
.notification-card h2 {
  margin-top: 0;
  margin-bottom: 5px;
}
</style>
