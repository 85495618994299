/*=========================================================================================
  File Name: moduleVideoActions.js
  Description: Video Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    if (item.modelId === null || item.modelId === undefined) {
      item.modelId = "";
    }

    return new Promise((resolve, reject) => {
      axios.post(`API/Notification/SendNotification?modelId=${item.modelId}&TouserId=${encodeURIComponent(item.toUserId)}&Titel=${encodeURIComponent(item.title)}&body=${encodeURIComponent(item.body)}`)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },



  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("API/Notification/GetAllNotification")
        .then((response) => {
          commit('SET_Notifications', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID(context, itemid) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.get("API/Notification/GetNotificationById?Id="+ itemid)
        .then((response) => {
          debugger;
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteNotificationobject({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.delete("API/Notification/DeleteUserNotification?NotificationID="+item.id)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },



}
