<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar position-right parent="body" default-index="1" color="primary"
    class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? $t('AddNew') : $t('Update') }} {{ $t('Item') }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <vs-card title="icon">
        <span style="color:brown;text-algin: center;">{{ $t("MessageNote") }}</span>

        <h4>{{ $t('title') }}</h4>
        <vs-input class="w-full" v-model="title" v-validate="'required'" name="title" />
        <h4>{{ $t('body') }}</h4>
        <vs-input class="w-full" v-model="body" v-validate="'required'" name="body" />

        <div class=" w-full">
          <label class="text-sm opacity-75">{{ $t("Owner") }}</label>
          <v-select
            label="nameAr"
            class="mt-2 w-full"
            name="nameAr"
            v-model="toUserId"
            :reduce="(owner) => owner.id"
            :options="AdsManagements"
          />
        </div>

        <div class="w-full">
          <label class="text-sm opacity-75">{{ $t("UnitModel") }}</label>
          <v-select
            label="modelName"
            class="mt-2 w-full"
            name="modelName"
            v-model="modelId"
            :reduce="(unitModel) => unitModel.id"
            :options="unitModelOptions"
          />
        </div>

      </vs-card>

    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">{{ $t('Submit') }}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{ $t('Cancel') }}</vs-button>
    </div>

  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";
import moduleUintModel from "@/store/UintModel/moduleUintModel.js";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      Id: null,
      ShowOffersWithNoRestrict: false,
      title: '',
      body: '',
      modelId:0,
      toUserId:'',

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { id, title, body,modelId,toUserId } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.Id =id;
        this.title = title;
        this.nameEn = body;
        this.modelId= modelId;
        this.toUserId= toUserId
      }
    }
  },


  computed: {
    isSidebarActiveLocal: {

      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    AdsManagements() {
      debugger;
      return this.$store.state.AdsManagementList.AdsManagements;
    },
    unitModelOptions() {
      debugger;
      return this.$store.state.moduleUintModelList.unitModelOptions;
    },
    isFormValid() {
      return !this.errors.any() && this.Id;
    }
  },

  methods: {

    initValues() {
      if (this.data.id) return;
      this.Id = null;
      this.ShowOffersWithNoRestrict = false;
      this.title = '';
      this.body = '';
      this.modelId = null;
      this.toUserId ='';

    },

    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            Id: this.Id,
            title: this.title,
            body: this.body,
            modelId : this.modelId,
            toUserId : this.toUserId,
          };

          if (this.Id !== null && this.Id >= 0) {
            this.$store.dispatch("moduleNotificationList/updateItem", obj).then(() => {
              this.$store
                .dispatch("moduleNotificationList/fetchDataListItems")
              window.showSuccess();
            }).catch(err => {
              console.error(err);
            });
          } else {
            debugger;
            delete obj.Id;
            obj.popularity = 0;
            this.$store.dispatch("moduleNotificationList/addItem", obj).then(() => {
              this.$store
                .dispatch("moduleNotificationList/fetchDataListItems")
              window.showSuccess();
            }).catch(err => {
              console.error(err);
            });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },


  },

  components: {
    VuePerfectScrollbar
  },
  create(){
    if (!moduleUintModel.isRegistered) {
      this.$store.registerModule("moduleUintModelList", moduleUintModel);
      moduleUintModel.isRegistered = true;
    }
    this.$store.dispatch("moduleUintModelList/fetchDataListItems");

    if (!moduleAdsManagement.isRegistered) {
      this.$store.registerModule("AdsManagementList", moduleAdsManagement);
      moduleAdsManagement.isRegistered = true;
    }
    this.$store.dispatch("AdsManagementList/fetchDataListItems");


  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
